// google font config
// WebFontConfig = {
//     classes: false,
//     events: false,
//     google: { families: [ 'Roboto:400,700'/*, 'Source+Sans+Pro:400'*/ ] }
// };

// youtube iframe api
var tag = document.createElement('script');
    tag.src = "//www.youtube.com/iframe_api";
var script = document.getElementsByTagName('script')[0];
    script.parentNode.insertBefore(tag, script);
var player, everPlayed = false, playClicked = false;

function onYouTubeIframeAPIReady()
{
    player = new YT.Player('youtube_player',
    {
        events:
        {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
        }
    });
}

function onPlayerReady(event)
{
    (function($)
    {
        $(document).ready(function()
        {
            var $videoModal = $('#videoModal');
            $('.home-watch-button').prop('disabled', false).removeClass('disabled btn-loading').on('click', function()
            {
                $videoModal.modal('show');
            });
            $videoModal.modal({show:false});
            $videoModal.on('shown.bs.modal', function ()
            {
                event.target.playVideo();
            });
            $videoModal.on('hide.bs.modal', function ()
            {
                event.target.pauseVideo();
            });

            if (playClicked)
            {
                //playClicked=false;
                $('.home-watch-button').trigger("click");
            }
            // mobile modal close
            $('.modal-body table').on('click', function()
            {
                $videoModal.modal('hide');
            });
        });
    })(jQuery);
}

function onPlayerStateChange(event)
{
    if (event.data == YT.PlayerState.PLAYING && !everPlayed)
    {
        event.target.setVolume(80);
        (typeof ga != "undefined") && ga('send', 'event', 'Videos', 'Play', 'Homeowner video');
        everPlayed = true;
    }

    if (event.data == YT.PlayerState.ENDED)
    {
        (typeof ga != "undefined") && ga('send', 'event', 'Videos', 'End', 'Homeowner video');
        everPlayed = false;
        setTimeout(function()
        {
            $('#videoModal').modal('hide');
        }, 2000);
    }
}

(function($)
{
    var wf = document.createElement('script');
        wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
        wf.type = 'text/javascript';
        wf.async = 'true';
    var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(wf, s);

    var resizeTimer, $homeForm, $heading1, $heading2,
        formImages = [
            '/img/home_/5__.jpg',
            '/img/home_/1__.jpg',
            '/img/home_/4__.jpg',
            '/img/home_/2__.jpg'],
        formHeading2 = [
            'your destination for all home improvement projects ',
            'your destination for all home improvement projects ',
            'your destination for all home improvement projects ',
            'your destination for all home improvement projects '
        ],
        formHeading1 = [
            'Easiest way for home improvement projects',
            'We select the perfect contractors',
            'Our escrow service secures your payments',
            'Contractors paid based on milestones'
		]

    function onResize()
    {
        //console.log('resize');
        var height = 0, // browser height
            breakpoint = OTR.jRes.getBreakpoint();  // screen breakpoints
        if( typeof( window.innerWidth ) == 'number' )
            height = window.innerHeight;
        else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) )
            height = document.documentElement.clientHeight;
        else if( document.body && ( document.body.clientWidth || document.body.clientHeight ) )
            height = document.body.clientHeight;

        // if height is smaller than ... for different screens
        breakpoint=='xs' && height<520 && (height = 520);
        breakpoint=='sm' && height<520 && (height = 520);
        breakpoint=='md' && height<520 && (height = 520);
        breakpoint=='lg' && height<600 && (height = 600);

        //console.log(height, breakpoint);

        $('div.home_form_container').css('height', height+'px');
    }

    function downloadBgImage(index)
    {
        if (index >= formImages.length)
            index=0;
        $downloadingImage = $("<img src='"+formImages[index]+"'>");
        $downloadingImage.load(function(){
            setTimeout(function(){
                //$homeForm.css('background-image', 'url('+$downloadingImage.attr("src")+')');
                $heading1.html(formHeading1[index]);
                $heading2.html(formHeading2[index]);
                index = index+1;
                downloadBgImage(index);
            }, 7000);
        });
    }

    $(window).resize(function()
    {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(onResize, 250);
    });

    $(document).ready(function()
    {
        // slider
        var $slider = $('#storyBoard');
        $slider.royalSlider(
        {
            autoScaleSlider: false,
            keyboardNavEnabled: false,
            usePreloader: true,
            numImagesToPreload: 3,
            loop:true,
            transitionSpeed:600,
            imageScalePadding:0,
            transitionType: 'fade', //'move'
            //autoHeight:true,
            //autoScaleSlider: true,
            autoPlay: {
                enabled: true,
                pauseOnHover: true,
                delay: 4000
            }
        });
        var slider_api = $slider.data('royalSlider');
        slider_api.stopAutoPlay();

        // slider start on scroll
        var waypoint = $('div#how_it_works').waypoint(function(direction)
        {
            //console.log('play');
            slider_api.startAutoPlay();
            this.disable(); // disable waypoint
        }, {
            offset: '25%' // when element reaches to 25% top
        });

        // slow down sliding
        var debounce = $.debounce(100, function(e, delta)
        {
            if(delta < 0)
                $slider.data('royalSlider').next();
            else if(delta > 0)
                $slider.data('royalSlider').prev();
        });

        validateProjectForm();

        // footer
        //var t = $("#footer .footer-bottom");
        //$("body").width() < 768 ? t.find(".new-copyright").length || (t.find(".address").after('<div class="new-copyright"></div>'), t.find(".copyright").appendTo("#footer .footer-bottom .new-copyright")) : (1==1); // : t.find(".new-copyright").length && (t.find(".copyright").prependTo("#footer .footer-bottom .row"), t.find(".new-copyright").remove());

        // scroll to top
        $("button.scroll-top").click(function() {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            return false;
        });



        $('.home-watch-button').on('click', function()
        {
            playClicked = true;
        });

        $homeForm = $('div.home_form');
        $heading1 = $('h1.home-heading1 span');
        $heading2 = $('h2.home-heading2');
        onResize();
        /*OTR.jRes.getBreakpoint()!='xs' &&*/ downloadBgImage(1);
    });
})(jQuery);


