function validateProjectForm()
{
    //console.log('validateProjectForm');
    var $zip = $("input#zip_code_home"),
        $description = $("textarea#description_home");

    // tooltips
    $description.tooltip({trigger:'manual', placement:'top', title: 'Please enter your project description', html: true});
    $zip.tooltip({trigger:'manual', placement:'top', title: $zip.data('error'), html: true});

    // form
    $('form#home-searchform').on('submit', function(e)
    {
        var validDescription = true,
            validZip = true,
            $form = $(this);

        //alert("zip1: '"+$zip.val()+"'");

        if ($form.attr('action').indexOf('?zip=') > -1)
            return true;

        if ($.trim($description.val()).length==0)
        {
            validDescription = false;
            $description.tooltip('show');
        }
        else
            $description.tooltip('hide');

        if ($zip.val().length!=5)
        {
            //alert("zip2: '"+$zip.val()+"'");

            validZip = false;
            $zip.attr('data-original-title', $zip.data('error')).tooltip('fixTitle').tooltip('show');
        }
        else
        {
            //alert("zip3: '"+$zip.val()+"'");

            $.ajax(
                {
                    type: 'post',
                    url: OTR.zip_validation_url,
                    data: { zip: $zip.val() }
                })
                .done(function(data)
                {
                    if (data.success)
                    {
                        //alert("zip4: '"+$zip.val()+"'");
                        validZip=true;
                        $zip.tooltip('hide');
                    }
                    else
                    {
                        //alert("zip5: '"+$zip.val()+"'");
                        //console.log('nv');
                        validZip=false;
                        $zip.attr('data-original-title', data.message).tooltip('fixTitle').tooltip('show');
                    }
                })
                .fail(function(jq)
                {
                    validZip=false;
                    $zip.attr('data-original-title', 'Oops! Couldn\'t validate zip code. Please check back later.').tooltip('fixTitle').tooltip('show');
                })
                .then(function(data)
                {
                    //console.log(valid, $form);
                    if (validZip && validDescription)
                    {
                        $form.attr('action', $form.attr('action')+'?zip='+$zip.val()).submit();
                        //return true;
                    }
                }, function(jq)
                {
                });
        }

        e.preventDefault();
        //return false;
    });

    // zip
    $("input[name='zip']").mask("99999", {autoclear: false, placeholder: ''});
    /*$zip.on('keydown', function(e)
    {
        // Allow: backspace, delete, tab, escape, enter and F5
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 116]) !== -1 ||
            // Allow: Ctrl+A, Ctrl+X, Ctrl+C, Ctrl+R
            ((e.keyCode == 65 || e.keyCode == 88 || e.keyCode == 67 || e.keyCode == 82) && ( e.ctrlKey === true || e.metaKey === true ) ) ||
            // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40))
        {
            // let it happen, don't do anything
            $zip.tooltip('hide');
            return;
        }

        var dontShowToolTip = false;
        $zip.val().length==5 && /^\d+$/.test($zip.val()) && (dontShowToolTip=true);

        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105))
        {
            !dontShowToolTip && $zip.tooltip('show');
            e.preventDefault();
        } else {
            $zip.tooltip('hide');
        }

    });*/

    $description.one('keyup', function(e)
    {
        //if (!$description.hasClass('home-description-bigger'))
        $description.addClass('home-description-bigger');
    });
    $description.on('keyup', function(e)
    {
        $description.tooltip('hide');
    });
    /*$description.on('keyup', function(e)
     {
     console.log('123');
     if (e.keyCode==13)
     {
     if ($.trim($description.val()).length>0)
     $zip.focus();

     e.preventDefault();
     }
     });*/


    /*$slider.on('mousewheel', function(e, delta)
     {
     debounce(e, delta);
     e.stopPropagation();
     e.preventDefault();
     });*/
}